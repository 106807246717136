.Add-Field_Modal-container {
    width: 467px;
    height: 385px;
    object-fit: contain;
    border-radius: 10px;
    border: solid 1px #222;
    background-color: #171617;

}

.Add-Field_Modal-main-header {

    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #919191;


}

.Add-Field_Modal-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #171617;
    color: white;
}

.Add-Field_Modal-items-container-main {
    display: grid;
    grid-template: "auto auto";
}

.Add-Field_Modal-items-container {
    width: 200px;
    height: 72px;
    margin: 5px;
    display: flex;
    flex-direction: column;
}


.Add-Field_Modal-items-title {
    flex-grow: 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #5e5e5e;
}

.Add-Field_Modal-items-input {
    width: 180px;
    height: 37px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    padding-left: 10px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.25);
    border: solid 1.5px #242424;
    background-color: #171617;
    color: white;
    margin-top: 10px;
}

.Add-Field_Modal-items-switch {
    margin-top: 10px;

}

.Add-Field_Modal-items-submit-button {
    width: 180px;
    height: 37px;
    margin-top: 30px;
    border: solid 1px #815FF7 !important;
    background-color: rgba(68, 49, 135, 0.68);
    color: white;
    cursor: pointer;
    border-radius: 10px;
}

.Add-Field_Modal-items-submit-button:hover {
    border: solid 1px #8461fc !important;
    background-color: rgba(72, 52, 142, 0.68);

}
