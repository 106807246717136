.login-body {
    flex-grow: 0;
    width: 100%;
    height: 100vh;
    background-color: #090909;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.login-container {
    width: 497px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-logo {
    width: 478px;
    height: 141px;
}

.login-data-container {
    width: 497px;
    height: 373px;
    flex-grow: 0;
    margin: 39px 0 26px;
    padding: 26px 48px 24px;
    border-radius: 15px;
    background-color: rgba(217, 217, 217, 0.04);
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
}

.login-input-container {
    width: 401px;
    height: 162px;
    flex-grow: 0;
    margin: 0 0 14px;
    padding: 21px 27px 24px;
    border-radius: 19px;
    background-color: #066075;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-email-input {
    width: 347px;
    height: 51px;
    flex-grow: 0;
    margin: 0 0 14px;
    border-radius: 13px;
    background-color: #c5c5c5;
    padding-left: 10px;
}

.login-email-submit {
    width: 305px;
    height: 52px;
    flex-grow: 0;
    margin: 14px 21px 0;
    padding: 15px 71px 14px;
    border-radius: 13px;
    background-color: #00495b;
    border: 2px solid #005167;
    cursor: pointer;
}

.login-email-submit:hover {
    background-color: #005167;

}

.login-email-submit-span {
    flex-grow: 0;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.login-or-span {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.login-terms-text {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    width: 340px;
    margin-top: 20px;
}

.login-warning {
    width: 497px;
    margin: 26px 0 142px;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}