.react-flow__node-selectorNode {
    width: 260px;
    min-height: 60px;
    flex-grow: 0;
    border-radius: 8px;
    border: solid 1px #815FF7 !important;
    background-color: rgba(68, 49, 135, 0.68);
    display: flex;
    justify-content: center;
    align-items: center;
}

.Custom-Node-Title {

    flex-grow: 0;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e4e4e4;
}

.react-flow__node-selectorNode span {
    color: white;
}

.Custom-Node-Icon {
    flex-grow: 0;
    margin: 2px 14px 1px 0;
    padding: 2.3px 2.3px 2.3px;
}

.Custom-Node-collapse {
    position: absolute;
    right: 0;
    top: -20px;
}

.Custom-Node-divider {
    border: none;
    border-top: 1px solid #815FF7;
    /* Customize the border color and style as needed */
    margin: 10px 0;
    /* Adjust margin spacing as needed */
}

.Custom-Node-divider-last-item {

    margin: 10px 0;
    /* Adjust margin spacing as needed */
}

.Custom-Node-field-title {
    text-align: left;
    width: 150px;
    padding-left: 10px;
}

.Custom-Node-field-value {
    width: 100px;
    text-align: center;
}

.Custom-Node-field-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Custom-Node-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.Custom-Node-add-new-item-container {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
    font-size: 15px;

}

.Custom-Node-add-new-item-container:hover {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(68, 49, 135, 0.68);
    font-size: 18px;
}

.Custom-Node-add-new-item-container-active {
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;

}
.react-flow__node{
    width: max-content;
}