.chat-container {
    /* max-width: 350px; */
    width: 15%;
    border: solid 1.8px rgba(245, 245, 245, 0.08);
    background-color: #090909;
    display: flex;
    flex-direction: column;
    border: solid 1px #252526;
    position: relative;
      transition: transform 0.5s ease-in-out;

}

.chat-container-chat {
      transition: transform 0.5s ease-in-out;

    max-width: 1200px;
    width: 100%;
    border: solid 1.8px rgba(245, 245, 245, 0.08);
    background-color: #131314;
    display: flex;
    flex-direction: column;
    border: solid 1px #252526;
    position: relative;
}

.chat-header-container {
    height: 75px;
    width: -webkit-fill-available;
    background-color: #090909;
    display: flex;
    justify-content: space-between;
    padding: 0px 30px;
    align-items: center;
    border: solid 1px #252526;
    border-top: none;
}

.chat-header-icon-container {
    display: "flex";
    justify-content: "center";
    align-items: "center"
}

.chat-header-title {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.chat-header-icon {
    width: 23.7px;
    height: 23.7px;
    flex-grow: 0;
    padding: 0px 5px;
    border-radius: 4.4px;
}

.chat-message-box-container {
    position: absolute;
    bottom: 0;
    padding: 0;

}

.chat-message-box-text-container {
    width: 100%;
    min-height: 30px;
    flex-grow: 0;
    margin: 0px 10px 0px 10px;
    border-radius: 8.5px;
    border: solid 0.7px #454545;
    background-color: #131314;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.chat-message-box-text-icon {
    width: 20px;
    height: 16px;
    flex-grow: 0;
}

.chat-message-box-container-vertical-divider {
    width: 1px;
    /* Adjust the width of the divider */
    height: 30px;
    /* Adjust the height of the divider */
    background-color: #cccccca2;
    /* Adjust the color of the divider */
    margin: 0 5px;
    /* Adjust the margin around the divider */
}

textarea {
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none;
    /*remove the resize handle on the bottom right*/
}

.chat-message-box-container-textarea {
    background-color: transparent;
    flex-grow: 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #919191;
    resize: none;
    border: none;
    width: inherit;
    padding-left: 5px;
}

.chat-chat-list {
    height: -webkit-fill-available;
    overflow-y: scroll;
    padding-bottom: 100px;
    margin-bottom: 100px;
    padding-bottom: 100px;

}

.chat-message-container {
    padding-left: 10px;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}

.chat-message-container.bot{
    flex-direction: row-reverse;
}
.chat-message-container-avatar {
}

.chat-message-avatar {
    width: 34.9px;
    height: 34.9px;
    flex-grow: 0;
    margin: 0 9.8px 23px 0;
    border-radius: 50%;

}

.chat-message-container-text {
}
.chat-message-container-text-metadata {
    display: flex;
    flex-direction: row;

}
.chat-message-container-text-metadata.bot {
    flex-direction: row-reverse;

}
.chat-message-sender-name {
    height: 13.3px;
    flex-grow: 0;
    margin: 3.5px 13.2px 4.5px 9.8px;
    font-family: Gilroy-☞;
    font-size: 11.2px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #f5f5f5;
}

.chat-message-time {
    height: 13.3px;
    flex-grow: 0;
    margin: 3.5px;
    font-family: Gilroy-☞;
    font-size: 11.2px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #a1a1a1;
}

.chat-message-area {
    width: auto;
    flex-grow: 0;
    margin: 5px 0 0 5px;
    padding: 10px;
    margin-right: 10px;
    border: solid 0.7px #454545;
    background-color: #242424;
}

.chat-message-area.bot {
    background-color: #126782;
    color: white !important;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.chat-message-area.user {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.chat-message-area-text {
    flex-grow: 0;
    font-family: Poppins;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #969698;
    max-width: 200px;
    overflow-wrap: anywhere;
    
}
.chat-message-area-text.bot {
    color: white ;
}
