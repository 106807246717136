input {
    border: 0;
    outline: 0;
}

input:focus {
    outline: none !important;
}

.header-container {
    height: 70px;
    width: 100%;
    background-color: #090909;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #252526;
    border-top: none;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.8s ease; /* Add transition for width property */

}

.header-container-chat {
    width: 1200px;
    left: 0;
    right: 0;
    margin: auto;
    border: solid 1px #252526;
    padding: 0;
    transition: width 0.3s ease; /* Add transition for width property */

}

.header-container span {
    color: white;
}

.main-container {
    /* overflow-x: hidden; */
    /*overflow-y: hidden;*/
    width: 100vw;
    height: 100vh;
    display: flex;
}

.header-user-container {
    display: flex;
    flex-direction: row;
}

.header-user-collapse {
    right: 25px;
    top: 25px;
}

.header-user-avatar {
    width: 40px;
    height: 40px;
    margin: 4px;
    border-radius: 50%;
}

.header-logo {
    height: 48.7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.7px;
    padding: 13.7px;
    object-fit: contain;
}

.header-user-text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #d7d7d7;
    margin: 12px 5px 12.7px 10px;

}

.left-sidebar-main-container {
    max-width: 340px;
    width: -moz-fit-content;
    width: 100px;
    background-color: #090909;
    color: white;
    width: fit-content;
    display: flex;
}

.left-mini-sidebar-container {
    width: 90px;
    background-color: #090909;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.left-mini-sidebar-icon {
    width: 34px;
    height: 34px;
    margin-top: 30px;
    cursor: pointer;
    transition: transform 0.2s ease;
}


.left-mini-sidebar-icon:hover {
    width: 40px;
    height: 40px;
    transform: scale(1.05);

}

.left-sidebar-container {
    width: 250px;
    background-color: #131314;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.left-sidebar-search-container {
    width: 220px;
    height: 45px;
    flex-grow: 0;
    border-radius: 10px;
    background-color: #090909;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

}

.left-sidebar-search-icon {
    width: 15.8px;
    height: 15.8px;
    margin: 0 0.8px 0.8px 0;
}

.left-sidebar-search-input {
    height: 18px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #828282;
    background-color: transparent;
    padding-left: 5px;
}

.left-sidebar-option-container {
    display: flex;
    justify-content: start;
    align-self: start;
    margin-top: 5px;
    transition: transform 0.2s ease;
    cursor: pointer;

}

.left-sidebar-option-container:hover {
    transform: scale(1.05);

}

.left-sidebar-option-text {
    height: 21px;
    flex-grow: 0;
    margin: 2px 0 1px 15px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #919191;
}

.left-sidebar-option-icon {
    width: 24px;
    height: 24px;
    flex-grow: 0;
    margin: 0 5px 0 0;
    padding: 2px;
}

.Toolbar-main-container {
    height: 130px;
    width: -webkit-fill-available;
    background-color: #131314;
}

.Toolbar-stepbar-container {
    height: 86px;
    background-color: #131314;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: solid 1px #252526;
}

.Toolbar-stepbar-steps-container {
    width: fit-content;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 29px;
    padding: 12px 40px;
    border-radius: 8px;
    background-color: #090909;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Toolbar-stepbar-steps {
    flex-grow: 0;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #d7d7d7;
    cursor: pointer;
}

.Toolbar-stepbar-steps:hover {
    background-color: #161617;
}

.Toolbar-stepbar-steps-active {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 4px 17px;
    border-radius: 6px;
    background-color: #2d2d30;
}

.Toolbar-options-bar-main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 43px;
}

.Toolbar-options-bar-container {
    display: flex;
    align-items: center;
    padding-left: 25px;
}

.Toolbar-options-bar-options-image {
    width: 17.1px;
    height: 17.1px;
    margin-right: 22px;
    cursor: pointer;
}

.Toolbar-options-bar-options-image:hover {
    transform: scale(1.05);
}

.Toolbar-options-bar-search-container {
    width: auto;
    max-width: 200px;
    height: 34px;
    border-radius: 9px;
    border: solid 1px #252526;
    background-color: #090909;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}
.Toolbar-options-bar-search-icon {
    width: 17px;
    height: 17px;
    margin-left: 5px;
}

.Toolbar-options-bar-search-input {
    height: 18px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #828282;
    background-color: transparent;
    padding-left: 5px;
}
.main-container2{
    width: -webkit-fill-available;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #090909;
    align-items: center;
}
.toolbar-and-flow-container{
    width: -webkit-fill-available;
    position: relative;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    background-color:#090909
}